
import { WorkStatus } from "@/store/modules/activity.store";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class WorkStatusTag extends Vue {
    @Prop({ required: true }) workStatus!: WorkStatus;

    get colors() {
        if ([WorkStatus.won].includes(this.workStatus)) {
            return "bg-[#C6F3E9] text-green";
        }

        if ([WorkStatus.lost].includes(this.workStatus)) {
            return "bg-red bg-opacity-25 text-red";
        }

        return "text-primary-500 bg-primary-300";
    }
}
